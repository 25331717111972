body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  flex-direction: column;
}


#messages {
  list-style-type: none;
  padding: 10px;
  margin: 0;
  flex: 1;
  overflow-y: auto;
}

/*#messages div {
  padding: 8px;
  margin-bottom: 4px;
  position: relative;
  word-wrap: break-word;
}*/

.message {
  padding: 8px;
  margin-bottom: 4px;
  position: relative;
  word-wrap: break-word;
  border-radius: 6px;
}

.message-user {
  font-weight: bold;
  margin-right: 5px;
}

.message-sent {
  background-color: #d1e7dd;
  text-align: right;
  padding-right: 75px;
}

.message-received {
  background-color: #f8d7da;
  text-align: left;
}

.message-system {
  background-color: #e2e3e5;
  text-align: center;
  font-style: italic;
}

#form {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
  background-color: #fff;
}

#send {
  padding: 10px;
  margin-left: 10px;
  border: none;
  background-color: #28a745;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.copy-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.copy-button:hover {
  color: #007bff;
}


.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.message-list_old {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.incoming-message {
  align-self: flex-start;
  background-color: #07d45d;
  color: white;
  border-bottom-left-radius: 0px !important;
}

.my-message {
  align-self: flex-end;
  background-color: #0080ff;
  color: white;
  border-bottom-right-radius: 0px !important;
}

.message-form {
  flex-shrink: 0;
}

.full-width {
  max-width: 100% !important;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.message-list {
  overflow: auto;
  height: calc(100% - 100px);
}

/* Form */

.message-form-container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  max-height: 50px;
}

#input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px 0px 0px 4px;
  width: calc(100% - 155px);
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.input-mobile{
  width: 100% !important;
  position: fixed !important;
  bottom: 0px;
  left: 0px;
}

.chat-button-holder{
  width: 175px;
  position: absolute;
  bottom:0px;
  right:0px;
  max-height: inherit;
}

.chat-buttons{
  height: 43px !important;
  width: 33% !important;
  border: 1px solid #ccc;
  background-color: #535966;
  display: inline;  
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.leftButton{
  border-radius: 4px 0px 0px 4px !important;
  float: left;
}

.rightButton{
  border-radius: 0px 4px 4px 0px !important;
  float: right;
}

.middleButton{
  border-radius: 0px !important;
}

/* Message Formatting */

#system-message {
  background-color: #f8d7da;
  text-align: center;
  padding: 10px;
  margin: 0;
  border-top: 1px solid #ccc;
}

.euiFormControlLayout {
  max-width: 100% !important;
}

.message-action {
  float: right;
  padding-left: 10px;
  margin: -4px;
}

.emojiChooser{
  position: absolute;
  bottom: 55px;
  right: 15px;
  z-index: 1000;
}

.emojiChooser-mobile {
  position: absolute;
  bottom: 50px;
  right: auto;
  left: auto;
  z-index: 1000;
}

.blur {
  filter: blur(5px);
  transition: filter 0.3s ease;
}

.blur:hover {
  filter: blur(0px);
}

.message-reactions{
  position: absolute;
  font-size: smaller;
}

#reactionPicker {
  position: absolute;
  bottom: 55px;
  right: 15px;
  z-index: 1000;
}